import {ItemProfile, ValidityPeriod} from '@/stores';
import {$Fetch, $fetch, FetchContext, FetchOptions, FetchResponse,} from 'ofetch';
import {unwrap} from 'solid-js/store';

export type Title = 'M' | 'MME' | 'MISS';

export interface Unpaid {
  cardOwnerCustomerId: string;
  cardSerialNumber: string;
  productCode: number;
  description: string;
  planId: string;
  firstName: string;
  lastName: string;
  psp: string;
  installments: UnpaidInstallment[];
}

export interface SvdPairing {
  found: boolean;
  message: string;
  canPairByCode: boolean;
  canPairByEmail: boolean;
  pairingEmail: string;
}

interface UnpaidInstallment {
  installmentFareProductItemId: number;
  installmentId: string;
  installmentStatus: string;
  paymentAmount: number;
  paymentDate: string;
}

export interface FeaturedProduct {
  id: number;
  sort: number;
  productId: number;
  image?: string;
  price: number;
  title: string;
  shortText: string;
  description: string;
  startAppearanceDate: string;
  endAppearanceDate: string;
}

export interface Discount {
  code: string;
  name: string;
  rate: number;
  price: number;
  monthlyPrice: number;
  thalesCodeCash: string;
  thalesCodeScheduled: string;
  createdAt: string;
}

export interface OrderDetails {
  id: number;
  customerId: string;
  total: number;
  status: string;
  paymentMode: string;
  currency: string;
  reference: string;
  collectAgencyId?: string | number;
  articles: OrderDetailsArticle[];
  deliveryAddress?: CustomerAddress;
  createdAt: string;
  updatedAt: string;
}

export interface OrderDetailsArticle {
  id: number;
  orderId: number;
  productId: number;
  recipientId: number;
  recipientRcuId: string;
  mediaId?: string | number;
  support: string;
  quantity: number;
  price: number;
  vatRate: number;
  isMonthlySubscription: boolean;
  deliveryAddress: DeliveryAddress;

  temporalValidity?: ValidityPeriod[];
}

export interface DeliveryAddress {
  streetNumber: string;
  streetName: string;
  deliveryPoint: string;
  deliveryPointSupplement: string;
  postalBox: string;
  postalCode: string;
  city: string;
  country: string;
  lastName: string;
  firstName: string;
}

export type User = Child & Customer

export interface Child {
  associationStatus: string;
  rcuId: string;
  customerId: string;
  thalesCustomerId: string;
  firstName: string;
  lastName: string;
  title: Title;
  email: string;
  secondaryEmail?: any;
  phoneNumber: string;
  mobileNumber: string;
  birthDate: string;
  profiles: ChildProfile[];
  picture?: {
	stream: string;
	metaData: string;
  };
}

interface ChildProfile {
  profileId: number;
  profileLabel: string;
  categoryId: string;
  category: string;
  expiresAt: string;
  startAt?: string;
}

interface Profile {
  id: number;
  thalesProfile: number;
  label: string;
  category: string;
  validityDuration?: string;
  expectedEvidences?: ExpectedEvidence[];
}

interface ExpectedEvidence {
  id: number;
  label: string;
}

export interface Order {
  id: number;
  reference: string;
  customerId: string;
  total: number;
  status: string;
  paymentMode: string;
  currency: string;
  collectAgencyId?: string | number;
  articles: OrderArticle[];
  createdAt: string;
  updatedAt: string;
}

export interface OrderArticle {
  id: number;
  orderId: number;
  productId: number;
  recipientId: number;
  mediaId: string;
  support: string;
  quantity: number;
  price: number;
  vatRate: number;
}

interface CreateOrderResponse {
  id: number;
  customerId: string;
  total: number;
  status: string;
  paymentMode: string;
  currency: string;
  collectAgencyId?: any;
  createdAt: string;
  updatedAt: string;
}

export interface CreateOrder {
  customerId?: string;
  paymentMode: string;
  currency: string;
  collectAgencyId?: number;
  deliveryAddress?: CustomerAddress;
  articles: CreateOrderArticle[];
  discountCode?: string;
}

interface PersonalizationData {
  birthDate: string;
  city: string;
  deliveryPoint: string;
  deliveryPointSupplement: string;
  postalBox: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  nationality: string;
  phone: string;
  photo: string;
  idCard: string;
  postalCode: string;
  state: string;
  street: string;
  title: string;
}

export interface CreateOrderArticle {
  productId: number;
  recipientId?: number;
  mediaId?: string;
  support: string;
  quantity: number;
  personalizationData?: Partial<PersonalizationData>;
  startDate?: string;
  profiles?: ItemProfile[];
  temporalValidity?: ValidityPeriod[];
  productOptionIds?: number[];
  companyCode?: number;
}

interface Media {
  id: string;
  label: string;
  origin: string;
  serialNumber: string;
  typeId: number;
  type: string;
  status: string;
  personalizationType: string;
  attached: boolean;
  contracts: Contract[];
  issuedAt: string;
  soldAt: string;
  expiresAt: string;
  updatedAt: string;
  createdAt: string;
  implicitProfile: number;
  explicitProfiles:
	  | null
	  | {
	profileId: number;
	category: string;
	expiryDate: string;
	startDate: string;
  }[];
}

export interface Contract {
  autoRenewOptions?: AutoRenewOptions;
  contractSerialNumber: number;
  status: string;
  product: string;
  productCode: number;
  productFamily: string;
  periodPassValidity: PeriodPassValidity;
  productConsumption?: ProductConsumption[];
  soldAt: string;
  installment?: {
	nextInstallment: string;
	planId?: string;
	rum?: string;
	payer?: {
	  thalesCustomerId: string;
	  firstname: string;
	  lastname: string;
	};
  };
}

interface ProductConsumption {
  initialValue: number;
  remainingConsumption: number;
}

interface PeriodPassValidity {
  periodicity: string;
  numberOfPeriod: number;
  validityStartDate: string;
  validityEndDate: string;
}

interface AutoRenewOptions {
  activationDate?: string;
  autoRenewDate?: string;
}

export interface CustomerAddress {
  streetNumber?: string;
  streetName: string;
  deliveryPoint?: string;
  deliveryPointSupplement?: string;
  postalBox?: string;
  postalCode: string;
  city: string;
  phone?: string;
  firstName: string;
  lastName: string;
  title: Title;
  country?: string;
}

interface ProprietaireCompteInternet {
  personnePhysique: PersonnePhysique;
}

interface BasketSummary {
  cashPayment: [],
  discountAmount: number,
  discountCode: string,
  schedulePayment: [
	{
	  productId: number,
	  productTitle: string,
	  unitPrice: number,
	  qty: number,
	  totalInstallments: number,
	  firstInstallmentDate: Date
	  firstInstallmentAmount: number
	}
  ]
  totalAmount: number
  warrantyDepositAmount: number
}

interface ScheduledPaiementInfo {
  orderDetails: {},
  formData: []
}

interface PersonnePhysique {
  idTechnique: string;
  civilite: string;
  nom: string;
  prenom: string;
  dateNaissance: string;
  optinTelephoneFixe: number;
  optinTelephoneProfessionnel: number;
  optinTelephoneMobile: number;
  optinSms: number;
  optinEmail: number;
  optinEmailProfessionnel: number;
  optinEmailCompteWeb: number;
  existant: number;
  dateCreation: string;
}

interface TechnicalAccounts {
  application: string;
  extId: string;
}

export interface Customer {
  customerId: string;
  thalesCustomerId: string;
  certifiedIdentity: boolean;
  firstName: string;
  lastName: string;
  kbmCustomerName: string;
  title: Title;
  email: string;
  secondaryEmail?: any;
  phoneNumber?: string;
  mobileNumber?: string;
  birthDate: string;
  technicalAccounts: TechnicalAccounts[];
  profiles: ChildProfile[];
  rcuId: string;
  photo?: {
	stream: string;
	metaData: string;
  };
  billingAddress?: CustomerAddress;
}

export interface CatalogCategoryResponse {
  id: number;
  name: string;
  parentId?: number;
}

export interface CatalogListResponse {
  id: number;
  name: string;
  smartContextTag?: string;
  children: ChildCategory[];
  products: Product[];
}

export interface ChildCategory {
  id: number;
  name: string;
  smartContextTag?: string;
  products: Product[];
}

export interface Product {
  warrantyDepositAmount: number | null;
  isCreditTime: boolean;
  id: number;
  title: string;
  shortText: string;
  description: string;
  categoryId: number;
  maxTicketQuantity: number;
  maxCardQuantity: number;
  price: number;
  monthlyPrice?: any;
  vatRate: number;
  isDailySubscription: boolean;
  isMonthlySubscription: boolean;
  isYearlySubscription: boolean;
  isNotSaleable: boolean;
  isScodi: boolean;
  allowMultipleSubscription: boolean;
  isOption: boolean;
  isOrganizationLink: boolean;
  mobilities: string[];
  profiles: number[];
  shopProfiles: ShopProfile[];
  allowedOptionGroups?: OptionGroup[];
  externalLink: string;
  validityStart?: string;
  validityEnd?: string;
  installmentPlanId?: number;
  unsaleableButtonLabel?: string;
  image: string;
  variants: Variant[];
  smartContextTag?: string;
  upsellImage?: string;
  upsellLink?: string;
  subscriptionDelay: number;
  linkedProductId?: number;
  cykleoProductType?: number;
}

interface OptionGroup {
  id: number;
  name: string;
  description: string;
  tooltip: string;
  options: number[];
  products?: Product[];
}

interface Variant {
  subscribersOnly: boolean;
  id: number;
  providerId: string
  thalesCode: string;
  support: string;
  paymentMode?: string;
  salesStart?: string;
  salesEnd?: string;
}

export interface ShopProfile {
  id: number;
  label: string;
  description: string;
  image: string;
  productId: number;
  isSaleable: boolean;
  thalesProfile?: number;
  externalLink: string;
  expectedEvidences?: ExpectedEvidence[];
}

interface ExpectedEvidence {
  id: number;
  label: string;
}

export interface ThalesUser {
  billingAddress: CustomerAddress;
  id: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  email: string | null;
  status: 'ENABLED' | 'DISABLED';
  title: string;
  mobileNumber?: string;
  phoneNumber?: string;
  createdAt: string;
  updatedAt: string;
}

export interface Iban {
  customerBankAccountId: number;
  thalesCustomerId: number;
  iban: string;
  insertionDate: string;
  customerBankAccountStatus: string;
}

export interface Voucher {
  products: VoucherProduct[];
  newBasketTotalValue: number;
  basketDiscountValue: number;
  oldBasketTotalValue: number;
}

export interface VoucherProduct {
  productId: number;
  qty: number;
  support: string;
  oldUnitPrice: number;
  discounted: boolean;
  newUnitPrice: number;
  percentOff?: number;
  unitOff?: number;
}

interface cykleo {
  cardNumber: string;
  subExtId: string;
  secretCode: string;
}

function auth(client: $Fetch) {
  const baseUrl = import.meta.env.VITE_AUTH_URL as string;

  return {
	async getConfig() {
	  return client(baseUrl + '/.well-known/openid-configuration');
	},
	async customer(token: string, params?: { rcuId?: string, thalesCustomerId?: string }) {
	  const customer = await client<User>('/eshop/customer', {
		headers: {
		  'Authorization': `Bearer ${token}`,
		},
		params
	  });

	  if (params) {
		return customer
	  }

	  try {
		const billing = await client<CustomerAddress>(
			'/eshop/customer/addresses/billing',
		);

		return {...customer, billingAddress: billing};
	  } catch {
		return customer;
	  }
	},
	async verifyUser(token: string) {
	  await client(`/eshop/auth/verify?token=${token}`, {
		headers: {
		  'Authorization': `Bearer ${token}`,
		}
	  });
	},
	async getAuthToken(config: {
	  token_endpoint: string;
	}, code: string, verifier: string, clientId: string) {

	  return await client(config.token_endpoint, {
		method: 'POST',
		headers: {
		  'content-type': 'application/x-www-form-urlencoded',
		},

		body: new URLSearchParams({
		  grant_type: 'authorization_code',
		  client_id: clientId,
		  code,
		  code_verifier: verifier,
		  redirect_uri: new URL(
			  location.origin + location.pathname,
		  ).toString(),
		}),
	  })
	},
	async refresh(config: {
	  token_endpoint: string;
	}, refreshToken: string, clientId: string) {
	  return await client(config.token_endpoint, {
		method: 'POST',
		headers: {
		  'content-type': 'application/x-www-form-urlencoded',
		},

		body: new URLSearchParams({
		  grant_type: 'refresh_token',
		  client_id: clientId,
		  refresh_token: refreshToken,
		}),
	  })
	},
  }
}

export interface SubscriptionExpires {
  recipientId: string;
  contracts: SubscriptionExpiresContract[];
}

export interface SubscriptionExpiresContract {
  mediaId: string;
  status: string;
  productCode: string;
  title: string;
  periodicity: string;
  autoRenewDate: string;
  validityStartDate: string;
  validityEndDate: string;
}

function customer(client: $Fetch) {
  let children: Child[];

  return {
	async medias(
		params: {
		  typeIds?: string;
		  thalesCustomerId?: string,
		  rcuId?: string
		} = {},
	): Promise<Media[]> {
	  const ONLY_CARD_V2 = 6;

	  if (params.thalesCustomerId || params.rcuId) {
		const cards = await client<Media[]>(`/eshop/customer/medias`, {
		  params: {...params, typeIds: ONLY_CARD_V2},
		});

		return cards.filter((card) => card.typeId === ONLY_CARD_V2);
	  }

	  return client<Media[]>('/eshop/customer/medias', {
		params: {...params, typeIds: ONLY_CARD_V2},
	  });
	},
	async addresses(params?: {
	  thalesCustomerId?: string | number;
	  rcuId?: string;
	}): Promise<CustomerAddress[]> {
	  const billing$ = await client<CustomerAddress>(
		  '/eshop/customer/addresses/billing',
		  {
			params,
		  },
	  );

	  if (billing$) {
		return [billing$];
	  }

	  const delivery$ = await client<CustomerAddress>(
		  '/eshop/customer/addresses/delivery',
		  {
			params,
		  },
	  );

	  return [delivery$];
	},
	updateAddress(
		thalesCustomerId: string | number | undefined,
		body: {
		  streetNumber?: string;
		  streetName?: string;
		  deliveryPoint?: string;
		  deliveryPointSupplement?: string;
		  postalBox?: string;
		  postalCode?: string;
		  city?: string;
		  country?: string;
		},
		rcuId?: string,
	) {
	  if (typeof body.country === 'object') {
		// @ts-expect-error: Error in thales R16 awaiting correction..
		body.country = body.country.value;
	  }
	  const params = thalesCustomerId ? {thalesCustomerId} : rcuId ? {rcuId} : undefined;

	  return client('/eshop/customer/addresses/billing', {
		method: 'PATCH',
		params,
		body,
	  });
	},
	clearCache() {
	  children = undefined;
	},
	async children(useCache = true) {
	  if (useCache && children) {
		return children;
	  }

	  children = await client<Child[]>('/eshop/customer/childrens');

	  return children;
	},
	addChild(body: {
	  thalesCustomerId: string
	}) {
	  return client('/eshop/customer/childrens', {method: 'POST', body});
	},
	updateChild(
		rcuId: string,
		body: {
		  rcuId?: string;
		  email?: string;
		  phoneNumber?: string;
		  mobileNumber?: string;
		},
	) {
	  return client(`/eshop/customer`, {
		method: 'PATCH',
		params: {
		  rcuId,
		},
		body,
	  });
	},
	updateChildAddress(
		thalesCustomerId: string,
		body: {
		  streetNumber: string;
		  streetName: string;
		  deliveryPoint?: string;
		  deliveryPointSupplement?: string;
		  postalBox?: string;
		  postalCode: string;
		  city: string;
		  country?: string;
		},
	) {
	  if (typeof body.country === 'object') {
		// @ts-expect-error Error in thales R16 awaiting correction..
		body.country = body.country.value;
	  }

	  return client(`/eshop/customer/childrens/${thalesCustomerId}/address`, {
		method: 'PATCH',
		body,
	  });
	},
	update(body: {
	  updateId?: string;
	  thalesCustomerId?: string;
	  email?: string;
	  phoneNumber?: string;
	  mobileNumber?: string;
	}) {
	  if (body.updateId) {
		const {updateId: rcuId, ...restOfBody} = body;

		return client('/eshop/customer', {
		  method: 'PATCH',
		  body: restOfBody,
		  params: {rcuId},
		});
	  }

	  return client('/eshop/customer', {method: 'PATCH', body});
	},
	removePairing(thalesCustomerId: string | number) {
	  return client(`/eshop/customer/childrens/${thalesCustomerId}`, {
		method: 'DELETE',
	  });
	},
	iban(): Promise<Iban> {
	  return client<Iban>(`/eshop/customer/iban`);
	},
	addIban(body: {
	  IBAN: string
	}) {
	  const IBAN = body.IBAN.replaceAll(' ', '');

	  return client<Iban>('/eshop/customer/iban', {
		method: 'POST',
		body: {IBAN},
	  });
	},
	mediasOrdered(params?: {
	  thalesCustomerId: string | number
	}) {
	  interface Media {
		orderNumber: number;
		orderDate: string;
		product: string;
		validityStartDate: string;
		validityEndDate: string;
	  }

	  return client<Media[]>('/eshop/customer/medias/ordered', {params});
	},
	async subscriptions(params?: {
	  rcuId?: string | number;
	  subscriptionType?: 'YEARLY' | 'MONTHLY' | 'DAILY';
	  providerId?: 'CYKLEO' | 'AIRWEB' | 'THALES' | 'INTERNE';
	}) {
	  interface Subscription {
		status: string;
		productCode: string;
		title: string;
		validityStartDate: string;
		validityEndDate: string;
		autoRenewDate?: string;
		periodicity?: 'YEARLY' | 'MONTHLY';
	  }

	  const subscriptions = await client<Subscription[]>(
		  '/eshop/customer/subscriptions',
		  {
			params,
		  },
	  );

	  return subscriptions.sort((a, b) => {
		return (
			new Date(b.validityEndDate).getTime() -
			new Date(a.validityEndDate).getTime()
		);
	  });
	},
	subscriptionsExpires() {
	  return client<SubscriptionExpires[]>(
		  '/eshop/customer/subscriptions/expires',
	  );
	},
	cancelPlan(params: {
	  planId: string | number;
	  thalesCustomerId?: string,
	  rcuId?: string
	}) {
	  return client(`/eshop/customer/installments/${params.planId}/cancel`, {
		method: 'POST',
		params: {
		  thalesCustomerId: params.thalesCustomerId
		},
	  });
	},
	cetificate(thalesCustomerId: string) {
	  const params = thalesCustomerId ? {thalesCustomerId} : {};

	  return client(`/eshop/customer/subscription/certificate`, {
		params,
	  });
	},
	schedule(thalesCustomerId: string) {
	  const params = thalesCustomerId ? {thalesCustomerId} : {};

	  return client(`/eshop/customer/subscription/schedule`, {
		params,
	  });
	},
	updateIban(body: {
	  newCustomerIBAN: string;
	  oldCustomerBankAccountId: number;
	}) {
	  return client(`/eshop/customer/iban`, {
		body,
		method: 'PUT',
	  });
	},
	unpaid() {
	  return client<Unpaid[]>(
		  `/eshop/customer/installmentPlans/unpaid/installments`,
	  );
	},
	pairingCykleo() {
	  return client('/eshop/customer/pairing/cykleo', {
		method: 'POST',
	  });
	},
	association(params: {
	  lastName: string;
	  firstName: string;
	  birthDate: string;
	  email?: string
	}) {
	  return client('/eshop/customer/association', {params});
	},
	associationValidation(params: {
	  lastName: string;
	  firstName: string;
	  birthDate: string;
	  email?: string,
	  code?: string
	}) {
	  return client('/eshop/customer/association/validation', {
		method: 'POST',
		body: {
		  ...params,
		},
	  });
	},
	cykleo() {
	  return client<cykleo>('/eshop/customer/cykleo')
	},
	acceptCGV() {
	  return client('/eshop/customer/acceptCGV', {
		method: 'POST',
	  });
	}
  };
}

function catalog(client: $Fetch) {
  const endpoints = {
	async list() {
	  const catalog = await client<CatalogListResponse[]>('/eshop/catalog?saleCatalog=SHOP');

	  // This reduce will filter out all non saleable products
	  // whether they are top level or nested within the children
	  return catalog.reduce<CatalogListResponse[]>((acc, item) => {
		const products = item.products.filter((product) => {

		  // A product is considered saleable if it doesn't have the `isNotSaleable` boolean
		  // set to true OR if it is an external product
		  const isSaleable =
			  !product.isNotSaleable || product.externalLink !== '';
		  return isSaleable;
		});

		const children = item.children
			.map((child) => {
			  // We only want product that are saleable OR have a link
			  const products = child.products.filter((product) => {

				// A product is considered saleable if it doesn't have the `isNotSaleable` boolean
				// set to true OR if it is an external product
				const isSaleable =
					!product.isNotSaleable || product.externalLink !== '';
				return isSaleable;
			  });

			  return {...child, products};
			})
			.filter((child) => child.products.length > 0);

		const shouldDisplayCategory =
			products.length > 0 ||
			children.some((child) => child.products.length > 0);

		return shouldDisplayCategory
			? [...acc, {...item, products, children}]
			: acc;
	  }, []);
	},
	async details(id: string | number) {
	  const product = await client<Product & {
		options?: Product[]
	  }>(
		  `/eshop/catalog/products/${id}`,
	  );

	  if (product.allowedOptionGroups) {
		for (const group of product.allowedOptionGroups) {
		  const options = [];
		  for (const option of group.options) {
			const resolvedOption = await endpoints.details(option);
			options.push(resolvedOption);
		  }

		  group.products = options;
		}
	  }

	  if (product.upsellImage && !product.upsellImage.includes('http')) {
		const image = await client(`/asset/${product.upsellImage}`);
		product.upsellImage = image.url;
	  }

	  return product;
	},
	categories() {
	  return client<CatalogCategoryResponse[]>('/eshop/catalog/categories');
	},
	search(params: {
	  duration: string;
	  frequency: string;
	  profile?: string
	}) {
	  return client<{
		recommendedProducts: {
		  productId: number;
		  productName: string
		}[];
		suggestedProducts: {
		  productId: number;
		  productName: string
		}[];
	  }>('/eshop/catalog/search', {params});
	},
	criterias() {
	  return client<{
		duration: {
		  key: string;
		  value: string;
		  frequency: {
			key: string;
			value: string;
			profile: {
			  key: string;
			  value: string
			}[];
		  }[];
		}[];
	  }>('/eshop/catalog/search/criterias');
	},
	featured() {
	  return client<FeaturedProduct[]>('/eshop/catalog/promotion');
	},
  };

  return endpoints;
}

function thales(client: $Fetch) {
  let profiles: Profile[];

  return {
	async search(params: {
	  id?: string;
	  lastName?: string;
	  firstName?: string;
	  birthDate?: string;
	}) {
	  if (params.id) {
		return client<ThalesUser>(`/eshop/thales/customers/${params.id}`, {
		  params: {birthDate: params.birthDate},
		});
	  }

	  return client<boolean>('/eshop/thales/customers', {params});
	},
	async create(body: {
	  title: string;
	  firstName: string;
	  lastName: string;
	  email: string;
	  birthDate: string;
	  phoneNumber?: string;
	  mobileNumber?: string;
	  billingAddress: {
		streetNumber: string;
		streetName: string;
		deliveryPoint?: string;
		deliveryPointSupplement?: string;
		postalBox?: string;
		postalCode?: string;
		city: string;
		country?: string;
	  };
	}) {
	  return client<ThalesUser>('/eshop/thales/customers', {
		method: 'POST',
		body,
	  });
	},
	async profiles() {
	  if (profiles) {
		return profiles;
	  }

	  profiles = await client<Profile[]>(
		  '/eshop/thales/configuration/profiles',
	  );

	  return profiles;
	},
  };
}

function svd(client: $Fetch) {
  return {
	async create(body: {
	  title: string;
	  firstName: string;
	  lastName: string;
	  email: string;
	  birthDate: string;
	  phoneNumber?: string;
	  mobileNumber?: string;
	  billingAddress: {
		streetNumber: string;
		streetName: string;
		deliveryPoint?: string;
		deliveryPointSupplement?: string;
		postalBox?: string;
		postalCode?: string;
		city: string;
		country?: string;
	  };
	}) {
	  return client('/eshop/customer/pairing/svd/creation', {
		method: 'POST',
		body,
	  });
	},
	submitEmailCodePairing(code: string, pairingId: string, rcuId?: string) {
	  return client('/eshop/customer/pairing/svd/validation/code', {
		method: 'POST',
		body: {
		  code,
		  pairingId,
		  rcuId,
		},
	  });
	},
	sendPairingEmail() {
	  return client('/eshop/customer/pairing/svd/validation', {
		method: 'POST',
		body: {
		  sendEmail: true
		},
	  });
	},
	checkPairing(passengerRcuId: string | boolean = '') {
	  if (passengerRcuId) {
		return client<SvdPairing>('/eshop/customer/pairing/svd?rcuId=' + passengerRcuId)
	  }
	  return client<SvdPairing>('/eshop/customer/pairing/svd')
	},
	submitCodePairing(code: string, rcuId?: string) {
	  return client('/eshop/customer/pairing/svd/validation', {
		method: 'POST',
		body: {
		  rcuId,
		  code,
		},
	  });
	},
  }

}

interface InstallmentPlan {
  id: number;
  description: string;
}

export interface PrecheckResult {
  valid: boolean,
  profiles: {
	profileId: number;
	valid: boolean,
	expiresAt: Date
	message: string
  },
  neededProofDocuments: string[],
  autoRenewDate: Date,
  minStartDate: Date,
  maxStartDate: Date,
  monthlySubscriptionAvailableDates: Date[],
  message: string,
  code: string
}

function installmentPlan(client: $Fetch) {
  return {
	details(id: number) {
	  return client<InstallmentPlan>(
		  `/eshop/thales/configuration/installmentPlans/${id}`,
	  );
	},
  };
}

function services(client: $Fetch) {
  return {};
}

function order(client: $Fetch) {
  let basketSummary: BasketSummary;

  return {
	precheck(body: {
	  rcuUserId: string;
	  productId: number;
	  support?: "RELOAD" | "TICKET" | "MOBILE";
	  paymentMode?: "CASH" | "SCHEDULED" | "FREE";
	}) {
	  return client<PrecheckResult>('/eshop/orders/precheck', {
		method: 'POST',
		body,
	  });
	},
	async history() {
	  const orders = await client<Order[]>('/eshop/orders');

	  return orders.reverse();
	},
	details(id: string) {
	  return client<OrderDetails>(`/eshop/orders/${id}`);
	},

	receipt(id: string) {
	  return client(`/eshop/orders/${id}/payment/receipt`);
	},
	token(orderId: string | number) {
	  return client<{
		formToken: string;
		free?: boolean
	  }>(
		  `/eshop/orders/${orderId}/payment`,
	  );
	},
	scheduledPaiementInfo(orderId: string | number, params?: {
	  vads_url_success: string,
	  vads_url_error: string;
	  vads_shop_url: string;
	}) {
	  return client<ScheduledPaiementInfo>(`/eshop/orders/${orderId}/payment/scheduled`, {
		params
	  });
	},
	async basketSummary(body: CreateOrder, useCache = true) {

	  if (useCache && basketSummary) {
		return basketSummary;
	  }

	  basketSummary =  await client<BasketSummary>('/eshop/orders/basketSummary', {
		method: 'POST',
		body,
	  });

	  return basketSummary;
	},
	create(body: CreateOrder) {
	  return client<CreateOrderResponse>('/eshop/orders', {
		method: 'POST',
		body,
	  });
	},
	emandate(body: {
	  ibanConfirmation: string
	}) {
	  return client<{
		token: string;
		paymentReference: string
	  }>(
		  '/eshop/orders/emandate',
		  {
			method: 'POST',
			params: body,
			body,
		  },
	  );
	},
	resendSMS(body: {
	  token: string
	}) {
	  return client<{
		maxAttemptsReached: boolean
	  }>(
		  '/eshop/orders/emandate/resend',
		  {
			params: body,
			method: 'POST',
		  },
	  );
	},
	validateEmandate(body: {
	  orderId: string;
	  token: string;
	  code: string
	}) {
	  return client<{
		token: string
	  }>('/eshop/orders/emandate/confirm', {
		method: 'POST',
		body,
	  });
	},
	validate(orderId: string | number) {
	  return client(`/eshop/orders/${orderId}/payment/validate`, {
		method: 'POST',
	  });
	},
	debtRepayment(body: {
	  currency: 'EUR';
	  unpaidList: Debt[]
	}) {
	  interface Res {
		id: number;
		reference: string;
		customerId: string;
		total: number;
		status: string;
		paymentMode: string;
		currency: string;
		collectAgencyId: number;
		paymentStatus: string;
		createdAt: string;
		updatedAt: string;
	  }

	  return client<Res>('eshop/orders/debtRepayment', {
		method: 'POST',
		body,
	  });
	},
  };
}

export interface Debt {
  productCode: number;
  planId: number;
  installmentIds: number[];
  recipientId?: number;
  psp: string;
}

export function paperworks(client: $Fetch) {
  return {
	upload(file: File) {
	  const formData = new FormData();
	  formData.append('file', file);

	  return client<{
		streamId: string
	  }>('/paperworks', {
		method: 'POST',
		body: formData,
	  });
	},
  };
}

export function employee(client: $Fetch) {
  return {
	applyDiscount(params: {
	  code: string
	}) {
	  return client<Discount>('/eshop/employee/companies', {params});
	},
	uploadCertificate(params: {
	  thalesCustomerId?: string
	}, file: File) {
	  const formData = new FormData();
	  formData.append('file', file);

	  interface Response {
		id: number;
		thalesCustomerId: string;
		documentId: string;
		status: string;
		createdAt: string;
		updatedAt: string;
	  }

	  return client<Response>('/eshop/employee/certificate', {
		params,
		method: 'POST',
		body: formData,
	  });
	},
  };
}

function assets(client: $Fetch) {
  return {
	upload(file: File) {
	  const formData = new FormData();
	  formData.append('file', file);

	  return client<{
		documentId: string
	  }>('/eshop/document/upload', {
		method: 'POST',
		body: formData,
	  });
	},
  };
}

function zendesk(client: $Fetch) {
  return {
	searchCustomerFailed(body: {
	  firstName: string;
	  lastName: string;
	  birthDate: string;
	  email: string;
	  mobileNumber: string;
	  tbmCustomerNumber: string;
	  description?: string;
	}) {
	  if (body.birthDate) {
		body.birthDate = new Date(body.birthDate).toLocaleDateString('fr-FR');
	  }

	  return client('/eshop/zendesk/forms/searchCustomerFailed', {
		body,
		method: 'POST',
	  });
	},
	searchIbanFailed(body: {
	  firstName: string;
	  lastName: string;
	  birthDate: string;
	  description: string;
	  email: string;
	  mobileNumber: string;
	  tbmCustomerNumber: string;
	  RIBDocumentToken: string;
	}) {
	  if (body.birthDate) {
		body.birthDate = new Date(body.birthDate).toLocaleDateString('fr-FR');
	  }

	  return client('/eshop/zendesk/forms/searchIbanFailed', {
		body,
		method: 'POST',
	  });
	},
	changePayer(body: {
	  firstName: string;
	  lastName: string;
	  birthDate: string;
	  email: string;
	  mobileNumber: string;
	  tbmCustomerNumber: string;
	  RIBDocumentToken: string;
	  MandateDocumentToken: string;
	  holderName: string;
	  contract: string;
	  contractInformation: string;
	  tbmHolderCustomerNumber: string;
	  TCN: string;
	}) {
	  if (body.birthDate) {
		body.birthDate = new Date(body.birthDate).toLocaleDateString('fr-FR');
	  }

	  return client('/eshop/zendesk/forms/changePayer', {
		body,
		method: 'POST',
	  });
	},
	uploadDocument(file: File) {
	  const formData = new FormData();
	  formData.append('file', file);

	  return client<{
		token: string
	  }>('/eshop/zendesk/forms/uploadDocument', {
		method: 'POST',
		body: formData,
	  });
	},
  };
}

export interface Fine {
  pvNumber: string;
  customerBirthDate: string;
  violationCode: string;
  lastName: string;
  firstName: string;
  amount: number;
  applicationFee: number;
  totalAmount: number;
  designation: string;
}

function fines(client: $Fetch) {
  return {
	async search(params: {
	  pvNumber: string;
	  customerBirthDate: string
	}) {
	  const fines = await client<Fine>('/eshop/fine', {params});

	  return {...fines, ...params};
	},
	async pay(body: {
	  pvNumber: string;
	  customerBirthDate: string;
	  billingAddress: CustomerAddress;
	}) {
	  const fine = await client<{
		formToken: string
	  }>(`/eshop/fine/payment`, {
		method: 'POST',
		body,
	  });

	  return fine;
	},
  };
}

export interface Agency {
  id: number;
  name: string;
  enable: boolean;
  message?: string;
  addressStreet: string;
  addressDetails: string;
  addressZipCode: string;
  addressCity: string;
  addressCountry: string;
  latitude: string;
  longitude: string;
}

function collect(client: $Fetch) {
  return {
	agencies() {
	  return client<Agency[]>('/eshop/collect/agencies');
	},
  };
}

interface Notice {
  id: number;
  position: number;
  label: string;
  content: string;
  totemPictureUrl?: any;
  bannerPictureUrl?: any;
  rectanglePictureUrl: string;
  mobilePictureUrl: string;
  externalUrl: string;
  startDate?: any;
  endDate?: any;
  createdAt: string;
}

function notices(client: $Fetch) {
  return {
	list() {
	  return client<{
		1: Notice;
		2: Notice;
		3: Notice;
		4: Notice;
	  }>('/eshop/notices');
	},
  };
}

export interface ClosureStatus {
  closed: boolean;
  message?: string;
  endAt?: string;
  startAt?: string;
}

function closureStatus(client: $Fetch) {
  return () => client<ClosureStatus>('/eshop/status');
}

type ExpirationCallback = (
	error?: FetchContext<any, any> & {
	  response: FetchResponse<ResponseType>;
	},
) => void;

export function createClient(baseURL: string) {
  const expirationCallback: ExpirationCallback[] = [];
  const beforeRequest: ((
	  options: FetchOptions,
	  headers: Headers,
  ) => Promise<[options: FetchOptions, headers: Headers]>)[] = [];

  const client = $fetch.create({
	baseURL,
	async onRequest({options, request}) {
	  // HACK: Somehow `ohmyfetch` breaks at some point if the body is a Proxy
	  options.body = unwrap(options.body);


	  let headers = new Headers(options.headers);

	  // TODO: Handle dynamic feed translations
	  headers.set('accept-language', 'fr-FR');

	  if (!request.toString().includes('realms/tbm')) {
		headers.set('x-api-key', import.meta.env.VITE_API_KEY as string);
	  }

	  for (const callback of beforeRequest) {
		// Basically we don't want
		// to have the middlewares callback running on auth endpoints because they
		// contain themselves API calls and that creates an infinite loop :sob:
		if (request.toString().includes('realms/tbm') || request.toString().includes('/auth') || (request.toString() === '/eshop/customer' && options.body === undefined)) {
		  continue;
		}

		// There might be edge cases not handled here
		[options, headers] = await callback(options, headers);
	  }

	  options.headers = headers;
	},
	async onResponseError(error) {
	  if (error.response.status === 401) {
		for (const callback of expirationCallback) {
		  callback(error);
		}
	  }

	  throw new Error(error.response.statusText, {cause: error.response});
	},
  });

  return {
	onAuthExpired(
		callback: (
			error?: FetchContext<any, any> & {
			  response: FetchResponse<ResponseType>;
			},
		) => void,
	) {
	  expirationCallback.push(callback);
	},
	addCallback(
		callback: (
			options: FetchOptions,
			headers: Headers,
		) => Promise<[options: FetchOptions, headers: Headers]>,
	) {
	  beforeRequest.push(callback);
	},

	employee: employee(client),
	auth: auth(client),
	catalog: catalog(client),
	thales: thales(client),
	svd: svd(client),
	customer: customer(client),
	paperworks: paperworks(client),
	services: services(client),
	order: order(client),
	assets: assets(client),
	zendesk: zendesk(client),
	fines: fines(client),
	collect: collect(client),
	installmentPlan: installmentPlan(client),
	notices: notices(client),
	closureStatus: closureStatus(client),
	applyVoucher(
		code: string,
		body: {
		  products: {
			support: string;
			qty: number;
			productId: number
		  }[]
		},
	) {
	  return client<Voucher>(`/eshop/voucher/${code}/verify`, {
		method: 'POST',
		body,
	  });
	},
  };
}
